import { Box, BoxProps, Button, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import React, { useState } from "react";
import * as WarningMsg from "./WarningMsg.json";
import WarningTriangle from "./images/WarningTriangle";

 const WarningBox: React.FC<{}> = () => {
    const [readMore, setReadmore]=useState(false);
    var shortMsg=WarningMsg.short;
    var linkText=readMore?"Visa mindre":"Läs mer";

    const fullMsg = WarningMsg.full;

    const toggleReadMore: any=()=>{
        setReadmore(!readMore);
    }
    return (
        <Box sx={{
            display:"flex",
            flexDirection:"column",
            boxSizing:"border-box",
            backgroundColor:"#EDECF0",
            width:"552px",
            padding:"16px",
            position:"relative",
            left:"50%",
            mt:"1rem",
            transform:"translateX(-50%)",
            minHeight:"144px",
            "@media (max-width: 625px)":{
                width:"324px",
                minHeight:"160px",
            }
            }}>
            <Box sx={{display:"flex", gap:"8px"}}>
                <WarningTriangle sx={{color:"#A55A0F", alignSelf:"start"}}/>
                <Typography sx={{fontWeight:600, fontSize:"16px", lineHeight:"24px"}}>Viktig information</Typography>
            </Box>
            <Box sx={{display:"flex", flexDirection:"column"}}>
                <Box sx={{
                    width:"100%",
                    fontSize:"14px",
                    lineHeight:"24px",
                    margin:"0 auto !important",
                    color:"#1D252C",
                    "@media (max-width: 625px)":{
                        fontSize:"16px",
                        width:"335px",
                        lineHeight:"24px"
                    }}}>
                {readMore?         
                    <List sx={{maxWidth:"100%", "@media (max-width: 625px)":{maxWidth:"90%"}}}>
                        {fullMsg.map((value) => (
                        <ListItem sx={{maxWidth:"100%", padding:0}}>
                            {/* <ListItemText sx={{maxWidth:"100%", fontSize:"14px !important"}} primary={value} /> */}
                            <Typography sx={{paddingBottom:"1rem", fontSize:"14px", maxwidth:"100%"}}>{value}</Typography>
                        </ListItem>
                        ))}
                    </List>
                :
                <List sx={{maxWidth:"100%", "@media (max-width: 625px)":{maxWidth:"90%"}}}>
                {shortMsg.map((value) => (
                <ListItem sx={{maxWidth:"100%", padding:0}}>
                    <Typography sx={{fontSize:"14px", maxWidth:"100%"}}>{value}</Typography>
                    {/* <ListItemText sx={{maxWidth:"100%", fontSize:"14px !important"}} primary={value}/> */}
                </ListItem>
                ))}
                </List>
                }
                </Box>
                <Button onClick={toggleReadMore} 
                disableRipple={true}
                variant="text" 
                sx={{textTransform: "none",
                textDecoration:"underline",
                position:"absolute",
                right:0,
                bottom:0,
                fontSize:"14px",
                lineHeight:"24px"
                }}>
                {linkText}</Button>
            </Box>
        </Box>
    )
};
export default WarningBox;
