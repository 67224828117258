import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const WarningTriangle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M5.49945 19C5.24677 19 4.9961 18.9396 4.77475 18.8258C4.05073 18.4524 3.78672 17.5994 4.18673 16.9236L10.685 5.72676C10.823 5.4928 11.0263 5.30302 11.2743 5.17484C11.497 5.06036 11.747 5 11.9984 5C12.139 5 12.279 5.01867 12.4157 5.05538C12.8011 5.15867 13.1197 5.39636 13.3131 5.72364L19.8153 16.9268C19.936 17.1308 20 17.3642 20 17.6C20 18.3722 19.3273 19 18.4999 19H5.49945ZM11.999 5.93333C11.9144 5.93333 11.8337 5.95324 11.7584 5.99182C11.6757 6.03413 11.6083 6.0976 11.5623 6.17476L5.0641 17.3716C4.9981 17.483 4.98276 17.6093 5.0201 17.7294C5.05743 17.8495 5.14143 17.9484 5.25811 18.0088C5.33211 18.0468 5.41545 18.0673 5.49945 18.0673H18.4999C18.776 18.0673 19 17.8576 19 17.6006C19 17.5222 18.9786 17.4444 18.9373 17.3754L12.4357 6.17227C12.3724 6.06524 12.2664 5.98622 12.1384 5.952C12.0924 5.93956 12.0457 5.93333 11.999 5.93333Z" fill="#0D3050"/>
      <path d="M11.9998 16.6667C11.9704 16.6667 11.9411 16.6642 11.9131 16.6599C11.5711 16.6225 11.2978 16.3674 11.2578 16.0482C11.2524 16.0215 11.2498 15.9941 11.2498 15.9667C11.2498 15.9393 11.2524 15.912 11.2578 15.8852C11.2978 15.566 11.5711 15.3115 11.9131 15.2742C11.9411 15.2692 11.9704 15.2673 12.0005 15.2673C12.0305 15.2673 12.0591 15.2698 12.0871 15.2742C12.4291 15.3115 12.7025 15.5666 12.7425 15.8858C12.7478 15.912 12.7498 15.9393 12.7498 15.9673C12.7498 15.9953 12.7471 16.0221 12.7425 16.0488C12.7025 16.368 12.4291 16.6232 12.0871 16.6605C12.0585 16.6648 12.0298 16.6667 11.9998 16.6667Z" fill="#0D3050"/>
      <path d="M11.9998 14.3333C11.7238 14.3333 11.4998 14.1242 11.4998 13.8666V9.66662C11.4998 9.40902 11.7238 9.19995 11.9998 9.19995C12.2751 9.19995 12.4998 9.40902 12.4998 9.66662V13.8666C12.4998 14.1242 12.2758 14.3333 11.9998 14.3333Z" fill="#0D3050"/>
    </SvgIcon>
  );
};

export default WarningTriangle;
